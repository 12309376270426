import { DateObject } from 'react-multi-date-picker'
import { Day } from './Day'
import { DayVariant } from './DatePickerContext'
import { DayPriceRange } from 'src/core/Availability/domain/CalendarDayStatus.model'

interface DayEvents {
  onClick?: () => void
  onMouseOver?: () => void
}

export const mapDay = (
  date: DateObject,
  dayVariant: DayVariant,
  priceRange: DayPriceRange,
  enabledDayEvents?: DayEvents,
) => {
  if (dayVariant.status === 'disabled') {
    return {
      children: (
        <Day
          date={date.toDate()}
          variant={dayVariant}
          priceRange={priceRange}
        />
      ),
    }
  }

  return {
    onClick: enabledDayEvents?.onClick,
    onMouseOver: enabledDayEvents?.onMouseOver,
    children: (
      <Day date={date.toDate()} variant={dayVariant} priceRange={priceRange} />
    ),
  }
}
