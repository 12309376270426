import { FC } from 'react'

export const SuccessIcon: FC = () => (
  <>
    <rect width="22" height="22" rx="11" fill="#0F5317" />
    <path
      d="M17 6.33337L9.88163 15.8245C9.71935 16.0411 9.47108 16.1765 9.20118 16.1957C8.93128 16.2149 8.66633 16.116 8.47501 15.9247L5 12.4497"
      stroke="#D9E8DB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SuccessIcon
