import { DateObject } from 'react-multi-date-picker'
import { Day } from './Day'
import { DayVariant } from './DatePickerContext'

interface DayEvents {
  onClick?: () => void
  onMouseOver?: () => void
}

export const mapDay = (
  date: DateObject,
  dayVariant: DayVariant,
  enabledDayEvents?: DayEvents,
) => {
  if (dayVariant.type === 'disabled') {
    return {
      disabled: true,
      children: <Day date={date.toDate()} variant={dayVariant} />,
    }
  }

  return {
    onClick: enabledDayEvents?.onClick,
    onMouseOver: enabledDayEvents?.onMouseOver,
    children: <Day date={date.toDate()} variant={dayVariant} />,
  }
}
