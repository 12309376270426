import React from 'react'
import { Flex } from 'src/ui/styles/objects/Flex'
import styles from './LegendPriceRange.module.scss'
import { Text } from 'src/ui/components'
import { DotsPriceRange } from './DotsPriceRange'
import { DayPriceRange } from 'src/core/Availability/domain/CalendarDayStatus.model'
import { useTrans } from 'src/ui/hooks/useTrans'

export const LegendPriceRange = () => {
  const { trans } = useTrans(['new-reservation'])

  const priceRange = [
    {
      priceRange: 'LOW' as DayPriceRange,
      label: trans('calendar_price-range-low'),
    },
    {
      priceRange: 'MEDIUM' as DayPriceRange,
      label: trans('calendar_price-range-medium'),
    },
    {
      priceRange: 'HIGH' as DayPriceRange,
      label: trans('calendar_price-range-high'),
    },
  ]

  return (
    <div className={styles.dotsContainer}>
      {priceRange.map(({ priceRange, label }) => (
        <Flex key={priceRange} gap="xxs" alignItems="center">
          <DotsPriceRange priceRange={priceRange} />
          <Text fontStyle={{ mobile: 'xs-300', laptop: 's-300' }}>{label}</Text>
        </Flex>
      ))}
    </div>
  )
}
