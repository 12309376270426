import { FC } from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import styles from './Day.module.scss'
import { DayVariant } from '../DatePickerContext'
import { Tooltip } from 'src/ui/components/molecules/Tooltip'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface DayProps {
  date: Date
  variant?: DayVariant
}

const variantClassName = {
  enabled: styles.enabledDate,
  disabled: styles.disabledDate,
}

export const Day: FC<DayProps> = ({ date, variant }) => {
  const currentDay = date.getDate()

  if (isDefined(variant)) {
    if (isDefined(variant.tooltip)) {
      return (
        <Tooltip
          yAxisOffset={12}
          tooltipContent={variant.tooltip}
          triggerElement={
            <Text
              fontStyle="s-700"
              className={variantClassName[variant.type]}
              ariaLabel={variant.tooltip}
            >
              {currentDay}
            </Text>
          }
        />
      )
    }

    return (
      <Text fontStyle="s-700" className={variantClassName[variant.type]}>
        {currentDay}
      </Text>
    )
  }

  return <Text fontStyle="s-700">{currentDay}</Text>
}
