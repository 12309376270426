import { FC, useEffect, useRef } from 'react'
import styles from './AlertToast.module.scss'
import { NotificationTypes } from '../Notification/NotificationTypes.model'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { useTrans } from 'src/ui/hooks/useTrans'
import { classNames } from 'src/ui/utils/classnames'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components/atoms/Text'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import {
  SuccessIconNotificationDark,
  ErrorIconNotificationDark,
  WarningIconNotificationDark,
  InfoIconNotificationDark,
} from 'src/ui/components/atoms/Icon/_icons/notifications'

interface Props {
  title?: string
  type: NotificationTypes
  isOpen: boolean
  closeAutomaticallyAfter?: number
  onClose?: () => void
  ariaLabel?: string
  'data-testid'?: string
  description?: string
  className?: string
}

const iconsByType = {
  success: SuccessIconNotificationDark,
  error: ErrorIconNotificationDark,
  warning: WarningIconNotificationDark,
  info: InfoIconNotificationDark,
}

export const AlertToast: FC<Props> = ({
  title = '',
  type,
  isOpen,
  closeAutomaticallyAfter,
  onClose,
  ariaLabel,
  'data-testid': testId,
  description,
  className,
}) => {
  const { trans } = useTrans(['common'])
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEscapeOnClose({ onClose })

  useEffect(() => {
    if (
      isUndefined(onClose) ||
      isUndefined(closeAutomaticallyAfter) ||
      isUndefined(isOpen)
    ) {
      return
    }

    timer.current = setTimeout(() => {
      onClose()
    }, closeAutomaticallyAfter)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [closeAutomaticallyAfter, isOpen, onClose])

  const handleNotificationClose = () => {
    if (isUndefined(onClose)) {
      return
    }

    if (timer.current) {
      clearTimeout(timer.current)
    }

    onClose()
  }

  return (
    <div
      className={classNames(styles.notificationWrapper, className)}
      aria-label={ariaLabel}
      role="dialog"
      data-testid={testId}
    >
      <div
        className={classNames(styles.notification, styles[type], className)}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.notificationContent}>
          {type && (
            <Icon size="l" color="icon-light" icon={iconsByType[type]} />
          )}
          <Flex direction="column" alignItems="flex-start">
            <Text fontStyle="m-500">{title}</Text>
            {description && <Text fontStyle="s-300">{description}</Text>}
          </Flex>
        </div>
        {onClose && (
          <button
            className={styles.exitButton}
            onClick={handleNotificationClose}
            aria-label={trans('modal_close-button_aria-label')}
          >
            <Icon size="m" color="icon-dark" icon={CloseIcon} />
          </button>
        )}
      </div>
    </div>
  )
}
