import { FC } from 'react'

interface Props {
  color: string
}

export const MoonIcon: FC<Props> = ({ color }) => (
  <svg viewBox="0 0 16 17" fill="none">
    <path
      d="M13.9999 8.99932C13.8951 10.1341 13.4692 11.2156 12.7721 12.1172C12.075 13.0188 11.1356 13.7032 10.0637 14.0904C8.99188 14.4775 7.83192 14.5514 6.7196 14.3034C5.60728 14.0554 4.5886 13.4957 3.78275 12.6898C2.97691 11.884 2.41723 10.8653 2.16921 9.75299C1.92118 8.64067 1.99508 7.48071 2.38224 6.40885C2.7694 5.337 3.45382 4.39757 4.35541 3.70049C5.257 3.00342 6.33847 2.57753 7.47327 2.47266C6.80888 3.3715 6.48917 4.47896 6.57229 5.5936C6.65541 6.70824 7.13584 7.75603 7.9262 8.54639C8.71656 9.33675 9.76435 9.81718 10.879 9.9003C11.9936 9.98342 13.1011 9.66371 13.9999 8.99932Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MoonIcon
