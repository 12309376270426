import { FC, MouseEventHandler, ReactElement } from 'react'
import styles from './MobileDatePicker.module.scss'
import dayStyles from './Day.module.scss'
import weekStyles from './Week.module.scss'
import { Calendar, DateObject } from 'react-multi-date-picker'
import { classNames } from 'src/ui/utils/classnames'
import { weekStartDayIndex } from 'src/ui/components/molecules/DatePicker/weekStartDayIndex'
import { locales } from 'src/ui/components/molecules/DatePicker/locales'

interface Props {
  checkIn: Date | undefined
  checkOut: Date | undefined
  locale: string
  maxDate: Date
  minDate: Date
  range: Date[]
  renderDay: (date: DateObject) => ReactElement
  renderNav: (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => ReactElement
  renderDayBlocked: (date: DateObject) => {
    disabled: boolean
    children: ReactElement
  }
  isOneDaySelected: boolean | undefined
  onDatesChange: (dates: DateObject[]) => void
  isDayBlocked: (day: Date) => boolean
  footer: ReactElement | ReactElement[]
}

export const MobileDatePicker: FC<Props> = ({
  checkIn,
  checkOut,
  locale,
  maxDate,
  minDate,
  range,
  isOneDaySelected,
  renderDay,
  renderDayBlocked,
  renderNav,
  onDatesChange,
  isDayBlocked,
  footer,
}) => {
  return (
    <div className={styles.container}>
      <Calendar
        className={classNames(
          checkIn && checkOut && dayStyles.selectedDays,
          isOneDaySelected && dayStyles.disableSelectSameDayTwice,
          styles.calendar,
          dayStyles.day,
          weekStyles.week,
        )}
        disableMonthPicker
        disableYearPicker
        shadow={false}
        monthYearSeparator=" "
        highlightToday={false}
        numberOfMonths={1}
        maxDate={maxDate}
        minDate={minDate}
        range
        rangeHover
        value={range}
        locale={locales(locale)}
        weekStartDayIndex={weekStartDayIndex(locale)}
        mapDays={({ date }: { date: DateObject }) => {
          if (isDayBlocked(date.toDate())) {
            return renderDayBlocked(date)
          }

          return {
            children: renderDay(date),
          }
        }}
        onChange={onDatesChange}
        renderButton={renderNav}
        plugins={[footer]}
      />
    </div>
  )
}
