import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { analytics } from 'src/core/Shared/infrastructure/analytics'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { CouponsFormValues } from 'src/ui/hooks/useCouponsForm'
import { useCart } from 'src/ui/contexts/CartContext'
import { getAppliedCoupons } from 'src/core/Cart/domain/Cart.model'

export const useEditAvailabilityActions = () => {
  const { queryUtils, navigate } = useApplicationRouter()
  const { cart } = useCart()

  const modifyDates = async (dates: CheckInCheckOut) => {
    analytics.actions.shared.changeDates(dates)

    const query = queryUtils.buildDatesQuery(dates)

    await navigate.toAvailabilityWhenEdited({
      ...query,
      ...getAppliedCoupons(cart),
    })
  }

  const modifyGuests = async (guests: GuestsFormValues) => {
    analytics.actions.shared.changeGuests(guests)

    const query = queryUtils.buildGuestsQuery(guests)

    await navigate.toAvailabilityWhenEdited({
      ...query,
      ...getAppliedCoupons(cart),
    })
  }

  const modifyCoupon = async (coupon: CouponsFormValues | undefined) => {
    analytics.actions.shared.changeCoupon(coupon?.value)

    const couponQuery = queryUtils.buildCouponQuery(coupon)
    await navigate.toAvailabilityWhenEdited(couponQuery)
  }

  return {
    modifyDates,
    modifyGuests,
    modifyCoupon,
  }
}
