import { Button, Text, Divider, Modal } from 'src/ui/components'
import styles from './OccupancyModal.module.scss'
import { FormProvider } from 'react-hook-form'
import { FormEvent } from 'react'
import { Translate } from 'src/ui/i18n/Translate'
import { useGuestsForm, GuestsFormValues } from 'src/ui/hooks/useGuestsForm'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { useCallCenter } from 'src/ui/hooks/useCallCenter'
import { GuestsForm } from 'src/ui/views/_components/GuestsForm'
import { Media } from 'src/ui/styles/objects/Media'
import { createModal } from 'src/ui/hooks/useModal'

interface Props {
  adultsList: number[]
  childrenList: number[]
  childrenAges: Array<Array<number>> | undefined
  hotel: Hotel
  onClose: () => void
  onSubmit: (guests: GuestsFormValues) => void
}

export const OccupancyModal = createModal(
  ({
    childrenList,
    adultsList,
    childrenAges,
    onClose,
    hotel,
    onSubmit,
  }: Props) => {
    const { trans } = useTrans(['new-reservation'])
    const { callCenterCountryCode, callCenterPhone, callCenterFreePhone } =
      useCallCenter()
    const {
      methods,
      expandedRoomId,
      uniqueIds,
      canAddMoreRooms,
      hasErrors,
      handleAddRoom,
      handleDeleteRoom,
      handleOpenRoom,
      validateFields,
    } = useGuestsForm({
      adultsList,
      childrenList,
      childrenAges,
    })

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault()

      validateFields(onSubmit, onClose)
    }

    return (
      <Modal
        size="m"
        mobileStyle="fullscreen"
        type="transactional"
        data-testid="occupancy-modal"
        onClose={onClose}
        withButtonsFooter
        data-quantum-modal="OccupancyModal"
      >
        <div className={styles.container}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit} noValidate className={styles.form}>
              <Media mobile tablet>
                <div className="mb-l">
                  <Text fontStyle="xl-700" color="dark">
                    {trans('occupancy-form_guests_main-title')}
                  </Text>
                </div>
              </Media>
              <div>
                {uniqueIds.map((uniqueId: string, roomIndex: number) => (
                  <div key={uniqueId}>
                    <GuestsForm
                      maxGuests={hotel.guestOptions.maxGuests}
                      maxChildren={hotel.guestOptions.maxChildren}
                      maxAdults={hotel.guestOptions.maxAdults}
                      maxChildAge={hotel.guestOptions.maxChildAge}
                      showChildren={!hotel.isAdultsOnly}
                      roomIndex={roomIndex}
                      roomUniqueId={uniqueId}
                      numberOfUniqueIds={uniqueIds.length}
                      expandedRoomId={expandedRoomId}
                      hasErrors={hasErrors}
                      onDeleteRoom={handleDeleteRoom}
                      onOpenRoom={handleOpenRoom}
                    />
                    {roomIndex < uniqueIds.length - 1 && (
                      <Divider dir="horizontal" />
                    )}
                  </div>
                ))}
                {!canAddMoreRooms && (
                  <Translate
                    i18nKey="new-reservation:occupancy-form_guests_add-room_max-rooms"
                    values={{
                      phone: `${callCenterPhone}${callCenterFreePhone}`,
                    }}
                    components={{
                      regular: (
                        <Text
                          className="mt-xl mb-l"
                          as="p"
                          fontStyle="m-300"
                          color="mid"
                        />
                      ),
                      bold: (
                        <Text
                          data-data-phone={callCenterCountryCode}
                          className={styles.phone}
                          fontStyle="link"
                          color="mid"
                        />
                      ),
                    }}
                  />
                )}
              </div>
              {canAddMoreRooms && (
                <Media mobile tablet>
                  <Button
                    variant="secondary"
                    size="fullWidth"
                    className="mt-l"
                    onClick={e => {
                      e.preventDefault()
                      handleAddRoom()
                    }}
                  >
                    {trans('occupancy-form_add-room')}
                  </Button>
                </Media>
              )}
            </form>
          </FormProvider>
          <div className={styles.buttonWrapper}>
            {canAddMoreRooms && (
              <Media laptop desktop>
                <Button
                  variant="secondary"
                  width="fit-content"
                  onClick={e => {
                    e.preventDefault()
                    handleAddRoom()
                  }}
                >
                  {trans('occupancy-form_add-room')}
                </Button>
              </Media>
            )}
            <Button
              size={{ mobile: 'fullWidth', laptop: 'large' }}
              onClick={handleSubmit}
            >
              {trans('occupancy-form_accept')}
            </Button>
          </div>
        </div>
      </Modal>
    )
  },
)
