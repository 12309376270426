import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { Time } from 'src/core/Shared/infrastructure/Time'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const isDayDisabled = ({
  day,
  checkIn,
  checkOut,
}: {
  day: Date
  checkIn?: Date
  checkOut?: Date
}) => {
  if (isDefined(checkIn) && isUndefined(checkOut) && checkIn > day) {
    return true
  }

  if (!datesManager.isBetweenValidDates(Time.fromDate(day))) {
    return true
  }

  return false
}
