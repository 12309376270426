import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  pick,
  uniqueId,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { generateUniqueIds } from 'src/ui/utils/generateUniqueIds'

export interface GuestsFormValues {
  adults: number[]
  children: number[]
  childrenAges: Array<Array<number>>
}

interface AvailabilityGuestsFormData {
  [fieldName: string]: number
}

interface Props {
  adultsList: number[]
  childrenList: number[]
  childrenAges?: number[][]
}

// ## TODO - ¿Sacar a sitio común de constantes? ¿Estos valores vendrán algún día de la API?
const DEFAULT_ADULTS = 2
const DEFAULT_CHILDREN = 0
const MAX_ROOMS = 10

export const useGuestsForm = ({
  adultsList,
  childrenList,
  childrenAges,
}: Props) => {
  const roomsNumber = adultsList.length
  const [uniqueIds, setUniqueIds] = useState<Array<string>>(() =>
    generateUniqueIds(roomsNumber),
  )
  const [expandedRoomId, setExpandedRoomId] = useState(uniqueIds[0])

  const methods = useForm<AvailabilityGuestsFormData>({
    mode: 'onChange',
    defaultValues: mapDefaultValues(
      adultsList,
      childrenList,
      childrenAges,
      uniqueIds,
    ),
  })

  const validateFields = (
    onSuccess: (guests: GuestsFormValues) => void,
    onFinally?: () => void,
  ) => {
    methods.handleSubmit(() => {
      onFinally?.()
      const guests = getAllValues()

      if (areValuesSameAsOld()) {
        return
      }

      onSuccess(guests)
    })()
  }

  const areValuesSameAsOld = () =>
    JSON.stringify({
      adults: adultsList,
      children: childrenList,
      childrenAges: childrenAges || [[]],
    }) === JSON.stringify(getAllValues())

  const getAllValues = () => {
    const { adults, children, childrenAges } = filterFormValuesByUniqueIds(
      methods.getValues(),
      uniqueIds,
    )

    return {
      adults,
      children,
      childrenAges,
    }
  }

  const resetForm = () => {
    methods.reset(
      mapDefaultValues(adultsList, childrenList, childrenAges, uniqueIds),
    )
  }

  const handleAddRoom = async () => {
    const isValid = await methods.trigger()

    if (!isValid) {
      return
    }

    const newUniqueId = uniqueId()
    methods.setValue(`adults#${newUniqueId}`, DEFAULT_ADULTS)
    methods.setValue(`children#${newUniqueId}`, DEFAULT_CHILDREN)
    setUniqueIdsCustom([...uniqueIds, newUniqueId])
    setExpandedRoomId(newUniqueId)
  }

  const handleDeleteRoom = (uniqueId: string) => {
    for (let i = 0; i < methods.getValues(`children#${uniqueId}`); i++) {
      methods.unregister(`childrenAge#${uniqueId}_${i}`)
    }
    setUniqueIdsCustom(uniqueIds.filter(id => id !== uniqueId))
  }

  const handleOpenRoom = async (uniqueId: string) => {
    const isValid = await methods.trigger()
    if (!isValid) {
      return
    }
    setExpandedRoomId(uniqueId)
  }

  const setUniqueIdsCustom = (uniqueIds: string[]) => {
    setUniqueIds(uniqueIds)

    if (uniqueIds.includes(expandedRoomId)) {
      return
    }

    setExpandedRoomId(uniqueIds[0])
  }

  return {
    methods,
    uniqueIds,
    expandedRoomId,
    canAddMoreRooms: uniqueIds.length !== MAX_ROOMS,
    roomsNumber: uniqueIds.length,
    hasErrors: Object.keys(methods.formState.errors).length > 0,
    validateFields,
    resetForm,
    areValuesSameAsOld,
    getAllValues,
    setExpandedRoomId,
    handleAddRoom,
    handleDeleteRoom,
    handleOpenRoom,
  }
}

const filterFormValuesByUniqueIds = (
  formValues: AvailabilityGuestsFormData,
  uniqueIds: string[],
) => {
  const validKeys = Object.keys(formValues).filter(key => {
    return uniqueIds.includes(
      key.substring(
        key.indexOf('#') + 1,
        key.lastIndexOf('_') !== -1 ? key.lastIndexOf('_') : key.length,
      ),
    )
  })

  const filteredFormValues = pick(formValues, validKeys)

  return {
    adults: Object.keys(filteredFormValues)
      .filter(key => key.includes('adults#'))
      .map(value => formValues[value]),
    children: Object.keys(filteredFormValues)
      .filter(key => key.includes('children#'))
      .map(value => formValues[value]),
    childrenAges: uniqueIds.map(uniqueId =>
      Object.keys(formValues)
        .filter(key => key.includes(`childrenAge#${uniqueId}_`))
        .map(value => formValues[value]),
    ),
  }
}

const mapDefaultValues = (
  adultsList: number[],
  childrenList: number[],
  childrenAges: Array<Array<number>> | undefined,
  uniqueIds: string[],
) => {
  const childrenAgesByRoom = childrenAges?.map((room, roomIndex) => {
    return room.reduce((accumulator, age, index) => {
      return {
        ...accumulator,
        [`childrenAge#${uniqueIds[roomIndex]}_${index}`]: age,
      }
    }, {})
  })

  return {
    ...adultsList.reduce((accumulator, adults, index) => {
      return { ...accumulator, [`adults#${uniqueIds[index]}`]: adults }
    }, {}),
    ...childrenList.reduce((accumulator, children, index) => {
      return { ...accumulator, ['children#' + uniqueIds[index]]: children }
    }, {}),
    ...(childrenAgesByRoom &&
      childrenAgesByRoom.reduce((acc, room) => {
        return { ...acc, ...room }
      }, {})),
  }
}
