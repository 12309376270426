import {
  ForwardRefRenderFunction,
  MouseEventHandler,
  forwardRef,
  useState,
} from 'react'
import { Media } from 'src/ui/styles/objects/Media'
import { isDayBlocked } from './isDayBlocked'
import { getTotalNights } from './getTotalNights'
import { DesktopDatePicker } from './DesktopDatePicker'
import { MobileDatePicker } from './MobileDatePicker'
import { Footer } from './Footer'
import { DateObject } from 'react-multi-date-picker'
import { Day } from 'src/ui/components/molecules/DatePicker/Day'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import {
  isDefined,
  isEmpty,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { NavIcon } from 'src/ui/components/molecules/DatePicker/NavIcon'

interface Props {
  checkIn: Date | undefined
  checkOut: Date | undefined
  error?: {
    message?: string
  }
  locale: string
  onDatesChange: (checkIn: Date, checkOut?: Date) => void
}

const DatePickerWithRef: ForwardRefRenderFunction<
  {
    set?: (key: string, value: number) => void
  },
  Props
> = ({ checkIn, checkOut, error, locale, onDatesChange }, ref) => {
  const [isOneDaySelected, setIsOneDaySelected] = useState<boolean | undefined>(
    false,
  )
  const languageConfig = useLanguageConfig()

  const isRTL = languageConfig.properties.isRTL
  const nights = getTotalNights(checkIn, checkOut)
  const maxDate = datesManager.getMaxDate()
  const minDate = datesManager.getMinDate()
  const range = [checkIn, checkOut].filter(isDefined)

  const renderNav = (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => {
    return (
      <NavIcon
        icon={getIcon(direction)}
        onClick={handleMonthChange}
        disabled={disabled}
      />
    )
  }

  const renderDay = (date: DateObject) => {
    return (
      <Day
        date={date.toDate()}
        isDayBlocked={isDayBlocked(date.toDate())}
        ariaLabel={datesManager.formatDateToLocale(
          date.toDate(),
          'long',
          'long',
        )}
      />
    )
  }

  const renderDayBlocked = (date: DateObject) => {
    return {
      disabled: true,
      children: (
        <Day
          date={date.toDate()}
          isDayBlocked={isDayBlocked(date.toDate())}
          ariaLabel={datesManager.formatDateToLocale(
            date.toDate(),
            'long',
            'long',
          )}
        />
      ),
    }
  }

  const getIcon = (direction: 'left' | 'right') => {
    if (!isRTL) {
      return direction
    }

    return direction === 'right' ? 'left' : 'right'
  }

  const handleDatesChange = (selectedDates: DateObject[]) => {
    if (isEmpty(selectedDates)) {
      return
    }

    onDatesChange(selectedDates?.[0]?.toDate(), selectedDates?.[1]?.toDate())
    setIsOneDaySelected(selectedDates?.length === 1)
  }

  return (
    <>
      <Media mobile tablet>
        <div>
          <MobileDatePicker
            checkIn={checkIn}
            checkOut={checkOut}
            isDayBlocked={isDayBlocked}
            maxDate={maxDate}
            minDate={minDate}
            range={range}
            isOneDaySelected={isOneDaySelected}
            onDatesChange={handleDatesChange}
            locale={locale}
            renderDay={renderDay}
            renderDayBlocked={renderDayBlocked}
            renderNav={renderNav}
            footer={
              <Footer
                key="footer"
                position="bottom"
                errorMessage={error?.message}
                nights={nights}
              />
            }
          />
        </div>
      </Media>

      <Media laptop desktop>
        <div>
          <DesktopDatePicker
            ref={ref}
            checkIn={checkIn}
            checkOut={checkOut}
            isDayBlocked={isDayBlocked}
            isOneDaySelected={isOneDaySelected}
            maxDate={maxDate}
            minDate={minDate}
            range={range}
            onDatesChange={handleDatesChange}
            locale={locale}
            renderDay={renderDay}
            renderDayBlocked={renderDayBlocked}
            renderNav={renderNav}
            footer={
              <Footer
                key="footer"
                position="bottom"
                errorMessage={error?.message}
                nights={nights}
              />
            }
          />
        </div>
      </Media>
    </>
  )
}

export const DatePicker = forwardRef(DatePickerWithRef)
