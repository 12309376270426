import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { container } from 'src/core/Shared/_di'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { BookingStepper } from './BookingStepper'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { StepId } from 'src/core/Shared/infrastructure/stepperManager'
import { IStep } from './BookingStepper.model'
import { getAppliedCoupons } from 'src/core/Cart/domain/Cart.model'
import { useCart } from 'src/ui/contexts/CartContext'

interface Props {
  currentStep: StepId
}

export const BookingStepperController: FC<Props> = ({ currentStep }) => {
  const [sessionMaxVisitedStep, setSessionMaxVisitedStep] = useState<StepId>(1)
  const { navigate, query } = useApplicationRouter()
  const { cart } = useCart()

  useEffect(() => {
    return container
      .resolve('eventsManager')
      .on(CustomEvent.RESET_PRE_RESERVE, () => {
        setSessionMaxVisitedStep(StepId.AVAILABLE_ROOMS)
      })
  }, [])

  useLayoutEffect(() => {
    const sessionMaxVisitedStep: StepId = container
      .resolve('stepperManager')
      .getMaxVisited() as StepId

    setSessionMaxVisitedStep(sessionMaxVisitedStep)
  }, [currentStep])

  const maxVisitedStep = container
    .resolve('stepperManager')
    .checkMaxVisited(currentStep, sessionMaxVisitedStep)

  const canNavigateToStep = (stepId: StepId) =>
    container
      .resolve('stepperManager')
      .canNavigateToStep(stepId, maxVisitedStep, currentStep)

  const navigateToStep = (stepId: StepId) => {
    const navigateToMap: { [key in StepId]: () => void } = {
      [StepId.AVAILABLE_ROOMS]: async () =>
        await navigate.toAvailabilityFromStepper({
          ...query,
          ...getAppliedCoupons(cart),
        }),
      [StepId.PERSONAL_DATA]: async () =>
        await navigate.toPersonalDataFromStepper(),
      [StepId.CHOOSE_PAYMENT]: async () =>
        await navigate.toChoosePaymentFromStepper(),
    }

    navigateToMap[stepId]()
  }

  const steps: IStep[] = [
    {
      id: StepId.AVAILABLE_ROOMS,
      label: 'stepper_room',
      enabled: canNavigateToStep(StepId.AVAILABLE_ROOMS),
      isCurrent: currentStep === StepId.AVAILABLE_ROOMS,
      visited: currentStep > StepId.AVAILABLE_ROOMS,
      completed: sessionMaxVisitedStep > StepId.AVAILABLE_ROOMS,
      canNavigateToNextStep: canNavigateToStep(StepId.CHOOSE_PAYMENT),
      navigateTo: () => navigateToStep(StepId.AVAILABLE_ROOMS),
      'data-testid': 'step-room',
    },
    {
      id: StepId.PERSONAL_DATA,
      label: 'stepper_personal-data',
      enabled: canNavigateToStep(StepId.PERSONAL_DATA),
      isCurrent: currentStep === StepId.PERSONAL_DATA,
      visited: currentStep > StepId.PERSONAL_DATA,
      canNavigateToNextStep: canNavigateToStep(StepId.CHOOSE_PAYMENT),
      completed: sessionMaxVisitedStep > StepId.PERSONAL_DATA,
      navigateTo: () => navigateToStep(StepId.PERSONAL_DATA),
      'data-testid': 'step-personal-data',
    },
    {
      id: StepId.CHOOSE_PAYMENT,
      label: 'stepper_payment-data',
      enabled: canNavigateToStep(StepId.CHOOSE_PAYMENT),
      isCurrent: currentStep === StepId.CHOOSE_PAYMENT,
      visited: currentStep > StepId.CHOOSE_PAYMENT,
      completed: sessionMaxVisitedStep > StepId.CHOOSE_PAYMENT,
      canNavigateToNextStep: false,
      navigateTo: () => navigateToStep(StepId.CHOOSE_PAYMENT),
      'data-testid': 'step-payment-data',
    },
  ]

  return <BookingStepper steps={steps} />
}
