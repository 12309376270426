import { FC, Fragment } from 'react'

import styles from './BookingStepper.module.scss'

import { useTrans } from 'src/ui/hooks/useTrans'

import { Step } from './Step'
import { StepDivider } from './StepDivider'
import { IStep } from './BookingStepper.model'

interface Props {
  steps: IStep[]
}

export const BookingStepper: FC<Props> = ({ steps }) => {
  const { trans } = useTrans(['common', 'new-reservation'])

  return (
    <nav
      className={styles.container}
      aria-label={trans('available-rooms_booking-stepper_label')}
    >
      {steps.map((step, i) => {
        const hasToShowDivider = i + 1 < steps.length

        return (
          <Fragment key={step.id}>
            <Step
              data-testid={step['data-testid']}
              stepId={step.id}
              label={trans(step.label)}
              navigateToStep={step.navigateTo}
              disabled={!step.enabled}
              visited={step.visited}
              isCurrent={step.isCurrent}
              completed={step.completed}
              aria-current={
                step.isCurrent &&
                trans('available-rooms_booking-stepper_current-step')
              }
            />
            {hasToShowDivider && (
              <StepDivider
                disabled={!step.enabled}
                visited={step.visited}
                canNavigateToNextStep={step.canNavigateToNextStep}
              />
            )}
          </Fragment>
        )
      })}
    </nav>
  )
}
