import { Time } from 'src/core/Shared/infrastructure/Time'

export interface CalendarDayStatus {
  date: Date
  priceRange: DayPriceRange
  restriction: CalendarDayRestriction
}

export type DayPriceRange = 'EMPTY' | 'LOW' | 'MEDIUM' | 'HIGH'

interface CalendarDayRestriction {
  status: DayRestriction
  // Indicates number of days for MIN_STAY or MAX_STAY restrictions
  quantity?: number
}

export type DayRestriction =
  | 'OPEN'
  | 'CLOSED'
  | 'NO_ARRIVAL'
  | 'NO_DEPARTURE'
  | 'MIN_STAY'
  | 'MAX_STAY'
  | 'LEAD_DAYS_TIME'

export interface CalendarStatusCriteria {
  hotelId: string
  month: Date
  market: string
}

export const areTwoMonthStatusLoaded = (
  daysStatus: CalendarDayStatus[],
  initialDate: Date,
) => {
  const firstMonth = Time.fromDate(initialDate)
  const secondMonth = Time.fromDate(initialDate).add(1, 'month')
  return (
    isMonthLoaded(daysStatus, firstMonth) &&
    isMonthLoaded(daysStatus, secondMonth)
  )
}

const isMonthLoaded = (daysStatus: CalendarDayStatus[], month: Time) => {
  return daysStatus.some(({ date }) =>
    month.isSame(Time.fromDate(date), 'month'),
  )
}

export const getFirstMonthNotLoaded = (
  daysStatus: CalendarDayStatus[],
  initialDate: Date,
) => {
  const firstMonth = Time.fromDate(initialDate)
  const secondMonth = Time.fromDate(initialDate)
    .add(1, 'month')
    .startOf('month')

  if (!isMonthLoaded(daysStatus, firstMonth)) {
    return firstMonth.startOf('month')
  }

  return secondMonth
}
