import { FC, MouseEventHandler, ReactElement } from 'react'
import styles from './MobileDatePicker.module.scss'
import dayStyles from './Day.module.scss'
import weekStyles from './Week.module.scss'
import { Calendar, DateObject } from 'react-multi-date-picker'
import { classNames } from 'src/ui/utils/classnames'
import { weekStartDayIndex } from '../weekStartDayIndex'
import { locales } from '../locales'
import { useDatePicker } from '../DatePickerContext'
import { BLoader } from 'src/ui/components/atoms/BLoader/BLoader'
import { mapDay } from '../mapDay'

interface Props {
  renderNav: (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => ReactElement
  onDatesChange: (dates: DateObject[]) => void
  footer: ReactElement | ReactElement[]
  hotelId: string
  market: string
}

export const MobileDatePicker: FC<Props> = ({
  renderNav,
  onDatesChange,
  footer,
  hotelId,
  market,
}) => {
  const {
    checkIn,
    checkOut,
    locale,
    minDate,
    maxDate,
    range,
    changeCurrentMonth,
    getDayVariant,
    isLoading,
  } = useDatePicker()

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <BLoader />
        </div>
      )}
      <Calendar
        className={classNames(
          checkIn && checkOut && dayStyles.selectedDays,
          styles.calendar,
          dayStyles.day,
          weekStyles.week,
        )}
        disableMonthPicker
        disableYearPicker
        shadow={false}
        monthYearSeparator=" "
        highlightToday={false}
        numberOfMonths={1}
        maxDate={maxDate}
        minDate={minDate}
        range
        rangeHover
        value={range}
        locale={locales(locale)}
        weekStartDayIndex={weekStartDayIndex(locale)}
        mapDays={({ date }: { date: DateObject }) => {
          const dayVariant = getDayVariant(date.toDate())
          return mapDay(date, dayVariant)
        }}
        onChange={onDatesChange}
        renderButton={renderNav}
        plugins={[footer]}
        onMonthChange={date =>
          changeCurrentMonth({ hotelId, market, month: date.toDate() })
        }
      />
    </div>
  )
}
