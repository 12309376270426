import styles from './BLoader.module.scss'

export const BLoader = () => {
  return (
    <div className={styles.logo}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 362.2 378.4"
        xmlSpace="preserve"
      >
        <g id={styles['b-fill']}>
          <path
            className={styles.st0}
            d="M228.1,178.7c17.2-10.5,25.6-25.6,25.6-46.1c0-39.1-23.5-58.1-71.7-58.1H80c-1.4,0-2.5,1.2-2.5,2.6v223.6 c0,1.5,1.1,2.6,2.5,2.6h105c36.4,0,79-17.7,79-67.5C264,207.9,251.3,187.8,228.1,178.7z M130.1,118h42.1c20.6,0,30.2,7.3,30.2,23.1 c0,19.8-14.5,24-26.6,24h-45.7V118L130.1,118z M178.6,260h-48.4v-56.1h49.3c21.4,0,31.7,9.6,31.7,29.3 C211.2,251.2,200.5,260,178.6,260z"
          ></path>
        </g>
        <g id={styles['b-hole-1']}>
          <path className={styles.st1} d="M130.1,165.1"></path>
          <path
            className={styles.st1}
            d="M175.8,165.1h-45.7V118l46.1,0.1c0,0,26.2-2.2,26.2,23S175.8,165.1,175.8,165.1z"
          ></path>
        </g>
        <g id={styles['b-hole-2']}>
          <path
            className={styles.st1}
            d="M181.3,260h-51.1v-56.1l51.1-0.1c0,0,29.7-1.9,29.7,28.1S181.3,260,181.3,260z"
          ></path>
        </g>
        <g id={styles['b-stroke']}>
          <path className={styles.st1} d="M77.5,141.6"></path>
          <path
            className={styles.st1}
            d="M77.5,165.2V76.9c0,0-0.1-0.7,0.7-1.5s1.5-0.8,1.5-0.8h101.6c0,0,70.1-4.4,72.2,52.1 c2.6,39.1-25.4,52.1-25.4,52.1s35.8,13.7,35.8,53.3c0,76.8-82.6,71.3-82.6,71.3H79.8c0,0-0.8,0-1.5-0.7s-0.7-1.6-0.7-1.6v-41 L77.5,165.2z"
          ></path>
        </g>
        <g id={styles['circle']}>
          <path
            className={styles.st2}
            d="M67.5,188.2v-0.5C68,124.7,119.6,74,182.6,74.5l0,0c62.9,0.5,113.5,51.8,113.2,114.6v0.5 c-0.3,63.2-51.9,114.2-115.1,113.7l0,0C117.7,302.7,67,251.2,67.5,188.2z"
          ></path>
        </g>
        <g id={styles['line-top']}>
          <line
            className={styles.st2}
            x1="37.5"
            y1="74.4"
            x2="293.9"
            y2="74.5"
          ></line>
        </g>
        <g id={styles['line-bottom']}>
          <line
            className={styles.st2}
            x1="293.9"
            y1="303.4"
            x2="37.5"
            y2="303.4"
          ></line>
        </g>
        <g id={styles['line-section-right']}>
          <line
            className={styles.st3}
            x1="276.2"
            y1="14.6"
            x2="232.5"
            y2="86.4"
          ></line>
        </g>
        <g id={styles['line-section-left']}>
          <line
            className={styles.st2}
            x1="77.5"
            y1="178.7"
            x2="67.4"
            y2="88.1"
          ></line>
        </g>
      </svg>
    </div>
  )
}
