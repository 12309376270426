import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  datesManager,
  MAX_NIGHTS,
} from 'src/core/Shared/infrastructure/datesManager'
import { Translator, useTrans } from './useTrans'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import {
  isUndefined,
  isDefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

const schema = (
  trans: Translator,
  defaultFormValues: DatesFormValues['dates'],
): yup.ObjectSchema<DatesFormValues> => {
  return yup.object({
    dates: yup
      .mixed<DatesFormValues['dates']>()
      .default(defaultFormValues)
      .test(
        'maximum reservation',
        trans('dates-range-form_maximum-reservation-nights'),
        dates => {
          if (isUndefined(dates)) {
            return true
          }

          const { checkIn, checkOut } = dates
          if (isUndefined(checkIn) || isUndefined(checkOut)) {
            return true
          }

          const nights = datesManager.calculateNights(checkIn, checkOut)
          return nights < MAX_NIGHTS
        },
      )

      .test('checkIn', trans('dates-range-form_select-check-in'), dates => {
        return isDefined(dates?.checkIn)
      })
      .test('checkOut', trans('dates-range-form_select-check-out'), dates => {
        return isDefined(dates?.checkOut)
      }),
  })
}

export interface DatesFormValues {
  dates: {
    checkIn?: Date
    checkOut?: Date
  }
}

type Props = CheckInCheckOut

export const useDatesRangeForm = ({ checkIn, checkOut }: Props) => {
  const { trans } = useTrans(['common'])

  const methods = useForm<DatesFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver<DatesFormValues>(
      schema(trans, { checkIn, checkOut }),
    ),
    values: {
      dates: {
        checkIn,
        checkOut,
      },
    },
  })

  const validateFields = (
    onSuccess: (dates: CheckInCheckOut) => void,
    onFinally?: () => void,
  ) => {
    methods.handleSubmit(() => {
      onFinally?.()
      const { checkIn: newCheckIn, checkOut: newCheckOut } = getValues()

      if (areValuesSameAsOld()) {
        return
      }

      onSuccess({
        checkIn: newCheckIn,
        checkOut: newCheckOut,
      })
    })()
  }

  const getValues = (): CheckInCheckOut => {
    const { checkIn: newCheckIn, checkOut: newCheckOut } =
      methods.getValues('dates')

    if (newCheckIn && newCheckOut) {
      return {
        checkIn: newCheckIn,
        checkOut: newCheckOut,
      }
    }

    return {
      checkIn,
      checkOut,
    }
  }

  const areValuesSameAsOld = () => {
    const { checkIn: newCheckIn, checkOut: newCheckOut } = getValues()

    return (
      datesManager.areDatesTheSame(checkIn, newCheckIn) &&
      datesManager.areDatesTheSame(checkOut, newCheckOut)
    )
  }

  const resetForm = () => {
    methods.reset({ dates: { checkIn: undefined, checkOut: undefined } })
  }

  const setManualErrorMessage = (message: string) => {
    methods.setError('dates', { type: 'manual', message })
  }

  return {
    methods,
    hasErrors: Object.keys(methods.formState.errors).length > 0,
    validateFields,
    resetForm,
    areValuesSameAsOld,
    getValues,
    setManualErrorMessage,
  }
}
