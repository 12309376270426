import { AvailabilityRepository } from '../domain/Availability.repository'
import { CalendarStatusCriteria } from '../domain/CalendarDayStatus.model'

interface Dependencies {
  availabilityRepository: AvailabilityRepository
}

export const getTwoMonthDaysStatus =
  ({ availabilityRepository }: Dependencies) =>
  (request: CalendarStatusCriteria) => {
    return availabilityRepository.twoMonthsStatus(request)
  }
